<template>
    <div>
        <b-card>
            <b-card-text>
                <b-row>
                    <b-col cols="3">
                        <label> 时间对比区间 </label>
                        <v-select
                            v-model="filters_selected.datetime.A"
                            :clearable="false"
                            :options="time_filters"
                            :reduce="(text) => text.value"
                            label="text"
                            required
                        />
                    </b-col>
                    <b-col cols="2">
                        <label>能源类型</label>
                        <v-select
                            v-model="filters_selected.energy_type"
                            :closeOnSelect="false"
                            :options="
                                $store.state.jsf35.energy_filters.energy_type
                            "
                            multiple
                            :placeholder="$t('No filter')"
                        />
                    </b-col>
                    <b-col
                        v-if="filters_selected.energy_type.includes('电')"
                        cols="3"
                    >
                        <label>电能分项</label>
                        <v-select
                            v-model="filters_selected.ec_type"
                            :closeOnSelect="false"
                            :options="$store.state.jsf35.energy_filters.ec_type"
                            multiple
                            :placeholder="$t('No filter')"
                        />
                    </b-col>
                    <b-col cols="3">
                        <label>位置</label>
                        <v-select
                            v-model="filters_selected.position"
                            :closeOnSelect="false"
                            :options="
                                $store.state.jsf35.energy_filters.position
                            "
                            multiple
                            :placeholder="$t('No filter')"
                        />
                    </b-col>
                    <b-col class="text-right pt-2" cols="1" offset="3">
                        <b-button variant="primary" @click="submit"
                            >查询</b-button
                        >
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>

        <b-row>
            <b-col cols="6">
                <b-card>
                    <b-card-title class="text-center">
                        {{
                            filters_selected.datetime.A === "year"
                                ? "今年"
                                : "本月"
                        }}用水汇总消耗趋势
                    </b-card-title>
                    <b-card-text>
                        <div style="height: 15rem">
                            <g2-mix
                                v-if="filtered_data_A"
                                :item="
                                    chart_item_options(
                                        1,
                                        filtered_data_A,
                                        'eqname'
                                    )
                                "
                                class="w-100 h-100"
                            ></g2-mix>
                            <h6 v-else class="mt-5 text-center">暂无数据</h6>
                        </div>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="6">
                <b-card>
                    <b-card-title class="text-center">
                        {{
                            filters_selected.datetime.A === "year"
                                ? "去年"
                                : "上个月"
                        }}用水汇总消耗趋势
                    </b-card-title>
                    <b-card-text>
                        <div style="height: 15rem">
                            <g2-mix
                                v-if="filtered_data_B"
                                :item="
                                    chart_item_options(
                                        2,
                                        filtered_data_B,
                                        'eqname'
                                    )
                                "
                                class="w-100 h-100"
                            ></g2-mix>
                            <h6 v-else class="mt-5 text-center">暂无数据</h6>
                        </div>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="6">
                <b-card>
                    <b-card-title class="text-center">
                        {{
                            filters_selected.datetime.A === "year"
                                ? "今年"
                                : "本月"
                        }}用水各楼层消耗趋势
                    </b-card-title>
                    <b-card-text>
                        <div style="height: 15rem">
                            <g2-mix
                                v-if="filtered_data_A"
                                :item="
                                    chart_item_options(
                                        5,
                                        filtered_data_A,
                                        'posiname',
                                        true
                                    )
                                "
                                class="w-100 h-100"
                            ></g2-mix>
                            <h6 v-else class="mt-5 text-center">暂无数据</h6>
                        </div>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="6">
                <b-card>
                    <b-card-title class="text-center">
                        {{
                            filters_selected.datetime.A === "year"
                                ? "去年"
                                : "上个月"
                        }}用水各楼层消耗趋势
                    </b-card-title>
                    <b-card-text>
                        <div style="height: 15rem">
                            <g2-mix
                                v-if="filtered_data_B"
                                :item="
                                    chart_item_options(
                                        6,
                                        filtered_data_B,
                                        'posiname',
                                        true
                                    )
                                "
                                class="w-100 h-100"
                            ></g2-mix>
                            <h6 v-else class="mt-5 text-center">暂无数据</h6>
                        </div>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import vSelect from "vue-select";
import {
    computed,
    onMounted,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api";
import store from "@/store";
import { momentFmt } from "@/libs/utils/moment";
import { showToast } from "@/libs/utils/showToast";
import g2Mix from "@/views/panel-group/components/g2-mix/View";
import { routerParams } from "@/libs/utils/routerParams";

export default {
    name: "index",
    components: {
        vSelect,
        g2Mix,
    },
    setup() {
        const system_key = routerParams("system_key");
        const event = reactive({
            filters_selected: {
                ec_type: [],
                energy_type: ["水"],
                position: [],
                datetime: {
                    A: "month",
                    B: "-month",
                },
            },
            //momentFmt(momentJs().startOf('quarter')) + ',' + momentFmt(momentJs())
            time_filters: [
                {
                    text: "与去年对比",
                    value: "year",
                },
                {
                    text: "与上个月对比",
                    value: "month",
                },
            ],
            filtered_data_A: null,
            filtered_data_B: null,
        });

        //查询结果数据合并
        const filtered_data_array = computed(() => {
            return event.filtered_data_A.concat(event.filtered_data_B);
        });

        const chart_item_options = (
            index,
            data,
            seriesField,
            isGroup = false
        ) => {
            return {
                panel_id: system_key + "_" + index,
                panel_name: "",
                type: "g2-mix",
                //数据查询的相关配置
                query_id: null,
                //options因所选组件不同，配置参数也不同
                options_type: 3,
                options: {
                    plots: [
                        {
                            region: {
                                start: { x: 0, y: 0.02 },
                                end: { x: 0.98, y: 1 },
                            },
                            type: "column",
                            data: data,
                            options: {
                                isGroup: isGroup,
                                xField: "time",
                                yField: "value",
                                seriesField: seriesField,
                                groupField: seriesField,
                                smooth: true,
                                meta: {
                                    value: {
                                        formatter: (val) => {
                                            return Math.round(val * 100) / 100;
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },
            };
        };

        watch(
            () => event.filters_selected.datetime.A,
            (type) => {
                event.filters_selected.datetime.B = "-" + type;
            }
        );

        //提交查询
        const submit = () => {
            event.filtered_data_A = null;
            event.filtered_data_B = null;
            let filters_selected = JSON.parse(
                JSON.stringify(event.filters_selected)
            );
            if (!filters_selected.datetime.A || !filters_selected.datetime.B) {
                showToast(null, "错误", "查询时间不能为空");
                return false;
            }
            store
                .dispatch("jsf35/fetchEnergyCompareDataList", {
                    project_id: 72,
                    filters: filters_selected,
                })
                .then((response) => {
                    showToast(null, "成功", "查询成功");
                    let fmt_data = { A: [], B: [] };
                    for (const dataKey in response.data) {
                        const dataArr = response.data[dataKey];
                        if (dataArr && dataArr.length > 0) {
                            dataArr.map((dataItem) => {
                                if (dataItem.value >= 0) {
                                    dataItem.value = parseFloat(
                                        dataItem.value.toFixed(2)
                                    );
                                    if (dataItem.posiname) {
                                        dataItem.posiname =
                                            dataItem.posiname.replace(
                                                "锦什坊街叁拾伍号",
                                                ""
                                            );
                                    }
                                    if (
                                        filters_selected.datetime.A === "month"
                                    ) {
                                        dataItem.time = momentFmt(
                                            dataItem.time,
                                            "s"
                                        );
                                    } else {
                                        dataItem.time = momentFmt(
                                            dataItem.time,
                                            "YYYY-MM"
                                        );
                                    }
                                    fmt_data[dataKey].push(dataItem);
                                }
                            });
                        }
                    }
                    event.filtered_data_A = fmt_data.A;
                    event.filtered_data_B = fmt_data.B;
                    console.log("查询结果", fmt_data);
                    //初始化图表
                })
                .catch((error) => {
                    showToast(error);
                });
        };

        onMounted(() => {
            submit();
            console.log("now", momentFmt());
        });

        return {
            ...toRefs(event),
            submit,
            filtered_data_array,
            chart_item_options,
        };
    },
};
</script>

<style lang="scss" scoped></style>
